import React, { FC } from 'react';
import {
  FiCheck,
  FiMinusCircle,
} from 'react-icons/fi';
import Loader from 'components/UI/Loader';
import { ResponseTranslation } from 'domains/campaigns/types';
import { useIsTranslation } from '../hooks/useIsTranslation';
import css from './styles.module.scss';
import DspTranslationInfo from './DspTranslationInfo';
import ApiTranslationOff from './ApiTranslationOff';
import ApiTranslationOn from './ApiTranslationOn';
import ErrorApiTranslation from './ErrorApiTranslation';

interface Props {
  isLoading: boolean;
  translationInfo: ResponseTranslation | null;
  onClickHandler: () => void;
  time: string | boolean;
  isError: boolean;
  type?: 'CAMPAIGN' | 'CREATIVE'
}

const getModerationChecklistIcon = (condition: boolean) => {
  if (condition) {
    return <FiCheck color="#149C08" size={16} />;
  }
  return <FiMinusCircle color="#828282" size={16} />;
};

const Translations: FC<Props> = ({
  isLoading,
  translationInfo,
  onClickHandler,
  time,
  isError,
  type
}) => {
  const {
    translationData: { isTranslation, isCreo, ...RestBroadcastData },
    DSPTranslationData,
  } = useIsTranslation(translationInfo);
  
  if (isError) {
    return <ErrorApiTranslation onClickHandler={onClickHandler} type={type} />;
  }

  if (isLoading || !translationInfo) {
    return (
      <div className={css.loader}>
        <Loader />
      </div>
    );
  }

  if (
    Object.values(RestBroadcastData).every((item) => item === true) &&
    !isTranslation
  ) {
    return (
      <DspTranslationInfo
        DSPTranslationData={DSPTranslationData}
        getModerationChecklistIcon={getModerationChecklistIcon}
        onClickHandler={onClickHandler}
        time={time}
      />
    );
  }
  if (Object.values(RestBroadcastData).every((item) => item === true)) {
    return (
      <ApiTranslationOn
        isCreo={isCreo}
        isTranslation={isTranslation}
        onClickHandler={onClickHandler}
      />
    );
  }
  return (
    <ApiTranslationOff
      broadcastData={RestBroadcastData}
      isCreo={isCreo}
      isTranslation={isTranslation}
      getModerationChecklistIcon={getModerationChecklistIcon}
      onClickHandler={onClickHandler}
      time={time}
      type={type}
    />
  );
};

export default Translations;
