import React, { FC } from 'react';
import cn from 'classnames';
import moment from 'moment';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import OnlyOwner from 'components/OnlyOwner';
import DateRangeFilter from 'components/DateRangeFilter';
import { parsingDate } from 'utils/momentParse';
import SwitchItem from 'components/UI/SwitchItem';
import { useTranslation } from 'react-i18next';
import { useUserInfo, useUserTheme } from 'domains/user/model/selectors';
import { KeysSettings, useStateValue } from 'utils/context';
import InternalId from 'components/InternalId';
import { useSwitchNavigation } from 'hooks/useSwitchNavigation';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { FiExternalLink } from 'react-icons/fi';
import { everyGroups } from 'utils/statics/everyGroups';
import { useDspLinks } from 'utils/useDspLinks/useDspLinks';
import Options from './Options';
import css from './styles.module.scss';
import { CreativeDetail } from '../../../types';
import ToggleSwitch from '../../../../../components/UI/ToggleSwitch';
import { TDates, TOnSetDateRange } from '../../../../../types/general';
import { useOnScroll } from '../../../../../hooks/useOnScroll';

interface Props<P> {
  creative: CreativeDetail | null;
  tab: string;
  period: P;
  type: TDates['type'];
  setType: (param: TDates['type']) => void;
  setPeriod: (period: P) => void;
  data_start: string;
  data_end: string;
  onSetIsShowStatistics: () => void;
  showStatistic: boolean;
  fullView?: boolean;
}

const Header: FC<Props<TDates['period']>> = ({
  creative,
  tab,
  type,
  period,
  setType,
  setPeriod,
  data_start,
  data_end,
  onSetIsShowStatistics,
  showStatistic,
  fullView = false,
}) => {
  const { data: user } = useUserInfo();
  const { t } = useTranslation();
  const {
    showNavigation,
    prevLink,
    nextLink,
    isLoading,
    count,
    isDisableLink,
  } = useSwitchNavigation({ mode: 'creo' });

  const { getJsonCreativeDspLink } = useDspLinks();

  const { isThemeExtended, getValueFromSettingForKey, isThemeFull } =
    useUserTheme();

  const { dispatch: dispatchContext } = useStateValue();

  const { fixed } = useOnScroll(120);

  const onSetDateRange: TOnSetDateRange = (periodDates, typeRange) => {
    setPeriod(periodDates);
    setType(typeRange);
    dispatchContext({
      type: 'setSettings',
      payload: {
        item: {
          type: typeRange,
          period: periodDates,
        },
        key: KeysSettings.CAMPAIGN,
      },
    });
  };

  return (
    <div className={css.wrapper}>
      <div className={cn(css.header, { isFixed: fixed })}>
        <div
          className={cn(css.container, 'container resized', {
            extend: isThemeExtended && fullView,
            full: isThemeFull && fullView,
          })}
        >
          <div className={css.breadcrumbs}>
            <Breadcrumbs xxhash={creative && creative.xxhash} type="Creative" />
          </div>
          <div className={css.info}>
            <div className={css.status}>
              <h1 className={css.title}>
                {creative && creative.internal_id && (
                  <InternalId internal_id={creative.internal_id} />
                )}
                {creative && `${creative.title}`}
                {everyGroups(user, 'test') && creative && (
                  <a
                    href={getJsonCreativeDspLink()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TooltipPoratal hint="get json dsp">
                      <FiExternalLink size={16} />
                    </TooltipPoratal>
                  </a>
                )}
              </h1>
            </div>
          </div>

          <div className={css.actions}>
            {tab === 'targeting' && (
              <>
                {(getValueFromSettingForKey('HideNavigationCreative') ||
                  getValueFromSettingForKey('HideNavigationCreative') ===
                    null) && (
                  <ToggleSwitch
                    onChange={onSetIsShowStatistics}
                    checked={showStatistic}
                    activateText={t(`targetings.show_statictics`)}
                    deactivateText={t(`targetings.show_statictics`)}
                  />
                )}

                <DateRangeFilter
                  range={period}
                  dateStart={parsingDate(
                    data_start || moment().subtract(365, 'days'),
                  ).toDate()}
                  dateEnd={parsingDate(data_end || new Date()).toDate()}
                  onSetDateRange={onSetDateRange}
                  initialTitleDay={type}
                />
                {creative && (
                  <>
                    <OnlyOwner>
                      <Options creative={creative} />
                    </OnlyOwner>
                  </>
                )}
              </>
            )}
            {(getValueFromSettingForKey('HideNavigationCreative') ||
              getValueFromSettingForKey('HideNavigationCreative') === null) && (
              <SwitchItem
                isDisableLink={isDisableLink}
                count={count}
                showNavigation={showNavigation}
                prevLink={prevLink}
                nextLink={nextLink}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
