import { useCampaignInfo } from 'domains/campaign/model/selectors';
import { useClientInfo } from 'domains/client/model/selectors';
import { useCreativeDetailInfo } from 'domains/creative/model/selectors';
import { isProductive } from 'utils/isProductive';

interface IDspLinksReturn {
  getJsonCreativeDspLink: () => string;
  getStatusCreativeJsonDspLink: () => string;
  getJsonCampaignDspLink: () => string;
  getStatusCampaignJsonDspLink: () => string;
  getJsonClientDspLink: () => string;
  getStatusClientJsonDspLink: () => string;
}

export const useDspLinks = (): IDspLinksReturn => {
  const creative = useCreativeDetailInfo().data;
  const campaign = useCampaignInfo().data;
  const partner = useClientInfo().data;

  const convertInternalId = (id: string | undefined): string =>
    id ? id.replace('#', '%23') : '';

  const getJsonCreativeDspLink = (): string =>
    isProductive()
      ? `http://192.168.13.8:1200/api/v1dev/creative/${convertInternalId(
          creative?.internal_id,
        )}`
      : `https://ads.bumlam.com/api/v1dev/creative/${convertInternalId(
          creative?.internal_id,
        )}`;

  const getStatusCreativeJsonDspLink = (): string =>
    isProductive()
      ? `http://192.168.13.8:1200/api/v1dev/creative/${convertInternalId(
          creative?.internal_id,
        )}/status`
      : `https://ads.bumlam.com/api/v1dev/creative/${convertInternalId(
          creative?.internal_id,
        )}/status`;

  const getJsonCampaignDspLink = (): string =>
    isProductive()
      ? `http://192.168.13.8:1200/api/v1dev/camp/${convertInternalId(
          campaign?.internal_id,
        )}`
      : `https://ads.bumlam.com/api/v1dev/camp/${convertInternalId(
          campaign?.internal_id,
        )}`;

  const getStatusCampaignJsonDspLink = (): string =>
    isProductive()
      ? `http://192.168.13.8:1200/api/v1dev/camp/${convertInternalId(
          campaign?.internal_id,
        )}/status`
      : `https://ads.bumlam.com/api/v1dev/camp/${convertInternalId(
          campaign?.internal_id,
        )}/status`;

  const getJsonClientDspLink = (): string =>
    isProductive()
      ? `http://192.168.13.8:1200/api/v1dev/partner/${convertInternalId(
          partner?.internal_id,
        )}`
      : `https://ads.bumlam.com/api/v1dev/partner/${convertInternalId(
          partner?.internal_id,
        )}`;

  const getStatusClientJsonDspLink = (): string =>
    isProductive()
      ? `http://192.168.13.8:1200/api/v1dev/partner/${convertInternalId(
          partner?.internal_id,
        )}/status`
      : `https://ads.bumlam.com/api/v1dev/partner/${convertInternalId(
          partner?.internal_id,
        )}/status`;

  return {
    getJsonCreativeDspLink,
    getStatusCreativeJsonDspLink,
    getJsonCampaignDspLink,
    getStatusCampaignJsonDspLink,
    getJsonClientDspLink,
    getStatusClientJsonDspLink,
  };
};
